$bgc:rgb(16,16,16);
.page{
    padding-top: 10rem;
    
}

.content{

    background: #212121;
    padding: 30px;

    
}

.row{
        


    .col{

        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(min(25rem,100%), 1fr));
        gap: 1rem;

    }
}


.img-main{
        width: 100%;
        aspect-ratio: 3/2;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 15px 15px 30px rgb(25, 25, 25),
        -15px -15px 30px rgb(60, 60, 60);

       

}


.masthead {

    background-size: cover;
    min-height: 48vh;

}


@media (max-width: 768px){

    .masthead{
        min-height: 30vh;
    }
}